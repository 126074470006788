@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
* {
  box-sizing: border-box
}

body {
  margin: 50px 0 0 0;
  font-family: 'Space Grotesk', sans-serif;
  background: #EEEEEE;
  overflow-x: hidden;

  

  @media (max-width: 425px){
    margin: 20px 0 0 0;
  
  }
}

